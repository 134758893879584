import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    ClickAwayListener,
    Grow,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import { addMonths, format, subMonths } from 'date-fns';
import { Availability, AvailabilityProduct } from '@travelity/api';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { groupBy } from 'lodash';
import { DayAvailability } from '../../../components/day-availability';
import MonthCalendar from '../../../components/month-calendar/month-calendar';
import { useExpand, useLog, useResizeRef } from '../../../hooks';

const monthFormat = 'MM-y';

export interface AvailabilityCalendarProps {
    events?: Availability[];
    isLoading?: boolean;
    selectedDay?: number;
    selectedTime?: number;
    selectedProductId?: string;
    openEvent: (
        product: AvailabilityProduct,
        day: number,
        time: number
    ) => void;
}

export const AvailabilityCalendar: React.FC<AvailabilityCalendarProps> =
    React.memo((props: AvailabilityCalendarProps) => {
        const {
            events,
            isLoading,
            selectedDay,
            selectedProductId,
            selectedTime,
            openEvent,
        } = props;
        useLog('AvailabilityCalendar', []);

        const groupByMonths = useMemo(() => {
            if (events) {
                return groupBy(events, ({ date }) => format(date, monthFormat));
            }
            return {};
        }, [events]);

        const [activeMonth, setActiveMonth] = useState(new Date());
        const prevMonth = useCallback(() => {
            setActiveMonth(subMonths(activeMonth, 1));
        }, [activeMonth]);
        const nextMonth = useCallback(() => {
            setActiveMonth(addMonths(activeMonth, 1));
        }, [activeMonth]);
        const prevMonthCount =
            groupByMonths[format(subMonths(activeMonth, 1), monthFormat)]
                ?.length || 0;
        const nextMonthCount =
            groupByMonths[format(addMonths(activeMonth, 1), monthFormat)]
                ?.length || 0;

        useEffect(() => {
            const months = Object.values(groupByMonths).map(([day]) => {
                return day.date;
            });
            months.sort();
            if (months[0]) {
                setActiveMonth(new Date(months[0]));
            }
        }, [groupByMonths]);

        const activeMonthGroupedByDay: Record<number, AvailabilityProduct[]> =
            useMemo(() => {
                const activeMonthAvailability =
                    groupByMonths[format(activeMonth, monthFormat)];
                if (activeMonthAvailability) {
                    return activeMonthAvailability.reduce(
                        (acc, { date, products: p }) => ({
                            ...acc,
                            [format(date, 'd')]: p,
                        }),
                        {}
                    );
                }
                return {};
            }, [groupByMonths, activeMonth]);

        const [expanded, toggleExpand] = useExpand<{
            day: number;
            date: Date;
            width?: number;
        }>();
        useEffect(() => {
            if (expanded && !activeMonthGroupedByDay[expanded.day]) {
                toggleExpand(null);
            }
        }, [activeMonthGroupedByDay, expanded, toggleExpand]);
        const refs = useRef<Record<number, HTMLDivElement | null>>({});
        const containerEl = useRef<HTMLDivElement | null>(null);

        const calendarContentEl = useMemo(
            () => containerEl.current?.querySelector('.month-container'),
            [containerEl.current]
        );

        const setDayRef = useCallback((ref: HTMLDivElement) => {
            const day = ref?.getAttribute('data-day');
            if (day) {
                refs.current[parseInt(day, 10)] = ref;
            }
        }, []);

        const expand = useCallback(
            (v?: { day: number; date: Date }) => {
                if (!v) {
                    toggleExpand(null);
                } else {
                    const state = {
                        ...v,
                        width: refs.current[v.day]?.clientWidth,
                    };
                    setTimeout(() => toggleExpand(state), 200);
                }
            },
            [toggleExpand]
        );

        const onClickAway = useCallback(
            (e: MouseEvent | TouchEvent) => {
                if (
                    (e.target as HTMLElement)?.closest?.('.calendar-container')
                ) {
                    toggleExpand(null);
                }
            },
            [toggleExpand]
        );

        // eslint-disable-next-line
        const [_, setContainerWidth] = useState(0);
        const onResize = useCallback(
            (w: number) => {
                if (expanded) setContainerWidth(w);
            },
            [expanded]
        );
        const containerRef = useResizeRef(onResize);

        const [fullScreen, setFullScreen] = React.useState<
            number | undefined
        >();

        const availableDates = useMemo(() => {
            return events?.map(({ date }) => date) || [];
        }, [events]);

        // const setToFullScreen = useCallback(
        //     (v: boolean) => setFullScreen(v ? expanded?.day : undefined),
        //     [expanded]
        // );

        const setFullScreenDay = useCallback(
            (d?: number) => setFullScreen(d !== undefined ? d : undefined),
            []
        );

        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    ref={containerRef}
                    sx={{
                        pt: 0.5,
                        pb: 1,
                        height: '52px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#B2B9CD',
                            fontSize: '16px',
                            fontWeight: '700',
                        }}
                    >
                        {format(activeMonth, 'MMMM yyyy')}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {!!prevMonthCount && (
                            <Button
                                startIcon={<NavigateBeforeIcon />}
                                sx={{
                                    color: '#565D6D',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    borderRadius: '16px',
                                    background: '#FFF',
                                    boxShadow:
                                        '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                                    p: '5px 12px',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(54, 134, 156, 0.04)',
                                    },
                                }}
                                onClick={prevMonth}
                            >
                                Previous {prevMonthCount}
                                {prevMonthCount > 1 ? ' Results' : ' Result'}
                            </Button>
                        )}
                        {!!nextMonthCount && (
                            <Button
                                endIcon={<NavigateNextIcon />}
                                sx={{
                                    color: '#565D6D',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    borderRadius: '16px',
                                    background: '#FFF',
                                    boxShadow:
                                        '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                                    p: '5px 12px',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(54, 134, 156, 0.04)',
                                    },
                                }}
                                onClick={nextMonth}
                            >
                                Next {nextMonthCount}
                                {nextMonthCount > 1 ? ' Results' : ' Result'}
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <MonthCalendar
                    ref={containerEl}
                    month={activeMonth}
                    render={useCallback(
                        ({ day, active, date, isToday }) => (
                            <DayAvailability
                                day={day}
                                date={date}
                                isLoading={isLoading}
                                eventsByProduct={
                                    active
                                        ? activeMonthGroupedByDay[day]
                                        : undefined
                                }
                                active={active}
                                isToday={isToday}
                                onEventClick={openEvent}
                                ref={setDayRef}
                                expand={expand}
                                fullScreen={fullScreen === day && active}
                                availableDates={availableDates}
                                setFullScreen={setFullScreenDay}
                                selectedTime={
                                    selectedDay === day
                                        ? selectedTime
                                        : undefined
                                }
                                selectedProduct={
                                    selectedDay === day
                                        ? selectedProductId
                                        : undefined
                                }
                            />
                        ),
                        [
                            isLoading,
                            activeMonthGroupedByDay,
                            openEvent,
                            setDayRef,
                            expand,
                            fullScreen,
                            availableDates,
                            setFullScreenDay,
                            selectedTime,
                            selectedDay,
                            selectedProductId,
                        ]
                    )}
                />
                <Popper
                    open={!!expanded}
                    anchorEl={expanded ? refs.current[expanded.day] : null}
                    placement="bottom"
                    modifiers={[
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: true,
                                altBoundary: true,
                                tether: false,
                                boundary: calendarContentEl,
                            },
                        },
                        {
                            name: 'flip',
                            enabled: false,
                        },
                    ]}
                    transition
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={onClickAway}>
                            <Grow {...TransitionProps} timeout={150}>
                                <div>
                                    {expanded && (
                                        <DayAvailability
                                            eventsByProduct={
                                                activeMonthGroupedByDay[
                                                    expanded.day
                                                ]
                                            }
                                            expanded={!!expanded}
                                            day={expanded.day}
                                            date={expanded.date}
                                            onEventClick={openEvent}
                                            width={expanded.width}
                                            maxHeight={
                                                calendarContentEl?.clientHeight
                                            }
                                            expand={expand}
                                            setFullScreen={setFullScreenDay}
                                            selectedTime={
                                                selectedDay === expanded.day
                                                    ? selectedTime
                                                    : undefined
                                            }
                                            selectedProduct={
                                                selectedDay === expanded.day
                                                    ? selectedProductId
                                                    : undefined
                                            }
                                        />
                                    )}
                                </div>
                            </Grow>
                        </ClickAwayListener>
                    )}
                </Popper>
            </>
        );
    });
