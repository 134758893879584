import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '@travelity/form';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
} from '@mui/material';
import {
    AddProductForm,
    CapacityItem,
    getCapacityDtoToCapacity,
    productDetailsToUpdateProductDetailsDto,
    Product,
    ProductCapacityItem,
    useUpdateProductCapacities,
    useUpdateProductOptions,
    useUpdateProductPricing,
    ProductScheduleItem,
    useUpdateProductConfiguration,
    useUpdateProductDetails,
    useUpdateProductRoute,
    useUpdateProductSchedules,
    useCapacities,
    ScheduleItem,
    scheduleDtoToScheduleItem,
    useSchedules,
} from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import {
    type GetCapacitiesResDto,
    GetSchedulesResDto,
} from '@travelity/api/src/requests';

import { LoadingOverlay } from '@travelity/ui';
import { ProductDetailsForm } from '../../../components/product-details';
import detailsSchema from '../../../components/product-details/product-details.schema';
import { ProductRouteForm } from '../../../components/product-route-form';
import routeSchema from '../../../components/product-route-form/product-route-form.schema';
import { ProductCapacityForm } from '../../../components/product-capacity-form';
import capacitySchema from '../../../components/product-capacity-form/product-capacity-form.schema';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import scheduleSchema from '../../../components/product-schedule-form/product-schedule-form.schema';
import { ProductFinancingForm } from '../../../components/product-financing-form';
import financialSchema from '../../../components/product-financing-form/product-financing-form.schema';
import { ProductOptionForm } from '../../../components/product-option-form';
import optionsSchema from '../../../components/product-option-form/product-option-form.schema';
import AccordionButtons from './accordion-buttons';
import { ActivityHistory } from '../../../components/activity-history';
import { ProductConfigurationForm } from '../../../components/product-configuration';

export interface ProductEditFormProps {
    product: Product;
}

const formNames = {
    productInfo: 'product details',
    route: 'route',
    capacity: 'capacity',
    schedule: 'schedule',
    financial: 'pricing and financials',
    options: 'options',
};

const ProductEditForm: React.FC<ProductEditFormProps> = ({ product }) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>(
        location.state || 0
    );
    const { canEdit } = product.permissions;

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };
    const onEdit = useCallback(() => setEditing(true), []);

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const { enqueueSnackbar } = useSnackbar();

    const { mutate: updateConfiguration, isLoading: isUpdatingConfiguration } =
        useUpdateProductConfiguration({
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated product details of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: ConfigurationForm,
        reset: resetConfiguration,
        formState: { errors: configurationErrors },
    } = useForm({
        defaultValues: product.configuration,
        onSubmit: useCallback(
            (
                data: AddProductForm['configuration'],
                e?: React.BaseSyntheticEvent
            ) => {
                e?.preventDefault();
                updateConfiguration({
                    productId: product.id,
                    data,
                });
            },
            [updateConfiguration]
        ),
        mode: 'onChange',
    });

    const { mutate: updateDetails, isLoading: isUpdatingDetails } =
        useUpdateProductDetails({
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated product details of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: DetailsForm,
        reset: resetDetails,
        formState: { errors: detailsErrors },
    } = useForm({
        defaultValues: product.productInfo,
        onSubmit: useCallback(
            (
                data: AddProductForm['productInfo'],
                e?: React.BaseSyntheticEvent
            ) => {
                e?.preventDefault();
                updateDetails({
                    productId: product.id,
                    data: productDetailsToUpdateProductDetailsDto({
                        productInfo: data,
                    }),
                });
            },
            [updateDetails]
        ),
        mode: 'onChange',
        schema: detailsSchema,
    });

    const { mutate: updateRoute, isLoading: isUpdatingRoute } =
        useUpdateProductRoute(product.route.stops, {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: RouteForm,
        reset: resetRoute,
        formState: { errors: routeErrors },
    } = useForm({
        defaultValues: product.route,
        onSubmit: useCallback(
            (data: AddProductForm['route'], e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                updateRoute({ items: data.stops, productId: product.id });
            },
            [updateRoute, product.id]
        ),
        mode: 'onChange',
        schema: routeSchema,
    });

    const { mutate: updateCapacities, isLoading: isUpdatingCapacities } =
        useUpdateProductCapacities(product.capacity.capacities, {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: CapacityForm,
        watch: watchCapacities,
        setValue: setValueCapacities,
        reset: resetCapacity,
        formState: { errors: capacityErrors },
    } = useForm({
        defaultValues: product.capacity,
        onSubmit: useCallback(
            (
                data: AddProductForm['capacity'],
                e?: React.BaseSyntheticEvent
            ) => {
                e?.preventDefault();
                updateCapacities({
                    items: data.capacities,
                    productId: product.id,
                });
            },
            [product]
        ),
        mode: 'onChange',
        schema: capacitySchema,
    });

    const { mutate: updateSchedules, isLoading: isUpdatingSchedules } =
        useUpdateProductSchedules(product.schedule, {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: ScheduleForm,
        watch: watchSchedules,
        setValue: setValueSchedules,
        reset: resetSchedule,
        formState: { errors: scheduleErrors },
    } = useForm({
        defaultValues: product.schedule,
        onSubmit: useCallback(
            (
                data: AddProductForm['schedule'],
                e?: React.BaseSyntheticEvent
            ) => {
                e?.preventDefault();
                updateSchedules({
                    items: data.items,
                    productId: product.id,
                    swing: data.swing,
                    duration: data.duration,
                });
            },
            [product]
        ),
        mode: 'onChange',
        schema: scheduleSchema,
    });

    const { mutate: updatePricing, isLoading: isUpdatingPricing } =
        useUpdateProductPricing(product.financial, {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: FinancialInformationForm,
        reset: resetFinancial,
        formState: { errors: financialErrors },
    } = useForm({
        defaultValues: product.financial,
        onSubmit: useCallback(
            (
                data: AddProductForm['financial'],
                e?: React.BaseSyntheticEvent
            ) => {
                e?.preventDefault();
                if (data)
                    updatePricing({
                        productId: product.id,
                        data,
                    });
            },
            [updatePricing]
        ),
        mode: 'onChange',
        context: {
            capacities: product.capacity?.capacities,
        },
        schema: financialSchema,
    });

    const { mutate: updateOptions, isLoading: isUpdatingOptions } =
        useUpdateProductOptions(product.options.items, {
            onSuccess: () => {
                setEditing(false);
                enqueueSnackbar(
                    // @ts-ignore
                    `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        });
    const {
        Form: OptionsForm,
        reset: resetOptions,
        formState: { errors: optionsErrors },
    } = useForm({
        defaultValues: product.options,
        onSubmit: useCallback(
            (data: AddProductForm['options'], e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                if (data)
                    updateOptions({ productId: product.id, items: data.items });
            },
            [updateOptions]
        ),
        mode: 'onChange',
        schema: optionsSchema,
    });

    const partialCapacities =
        watchCapacities('capacities')
            // @ts-ignore
            ?.filter((c: CapacityItem) => !c.type)
            .map((c: ProductCapacityItem) => c.capacityId) || [];
    const { isFetching: isCapacitiesFetching } = useCapacities(
        partialCapacities,
        {
            onSuccess: ({ items }: GetCapacitiesResDto) => {
                const oldCapacities = watchCapacities('capacities');
                // @ts-ignore
                const newCapacities = oldCapacities.map((c: CapacityItem) => {
                    if (c.type) return c;
                    const item = items?.find(i => i.id === c.capacityId);
                    if (item) return getCapacityDtoToCapacity(item);
                    return c;
                });
                // @ts-ignore
                setValueCapacities('capacities', newCapacities);
            },
        }
    );

    const partialSchedules =
        watchSchedules('items')
            ?.filter(
                // @ts-ignore
                (s: ScheduleItem) => !s.times?.length && !s.allDay && !s.isNew
            )
            .map((s: ProductScheduleItem) => s.scheduleId) || [];
    const { isFetching: isSchedulesFetching } = useSchedules(partialSchedules, {
        onSuccess: ({ items: its }: GetSchedulesResDto) => {
            const oldSchedules = watchSchedules('items');
            // @ts-ignore
            const newSchedules = oldSchedules.map((s: ScheduleItem) => {
                if (s.times?.length && !s.allDay) return s;
                // @ts-ignore
                const item = its?.find(i => i.id === s.scheduleId);
                if (item)
                    return { id: s.id, ...scheduleDtoToScheduleItem(item) };
                return s;
            });
            // @ts-ignore
            setValueSchedules('items', newSchedules);
        },
    });

    const reset = useCallback(() => {
        resetConfiguration(product.configuration);
        resetDetails(product.productInfo);
        resetRoute(product.route);
        resetCapacity(product.capacity);
        resetSchedule(product.schedule);
        resetFinancial(product.financial);
        resetOptions(product.options);
    }, [expanded, product]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [product]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 38px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        marginBottom: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Product Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <ConfigurationForm id="productConfiguration">
                        <Accordion
                            disableGutters
                            expanded={expanded === 0}
                            onChange={() => toggleExpanded(0)}
                        >
                            <AccordionSummary>
                                Product Configuration
                                {expanded === 0 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isUpdatingConfiguration}
                                        errors={configurationErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductConfigurationForm
                                    preview={!editing || expanded !== 0}
                                />
                                {isUpdatingConfiguration && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </ConfigurationForm>
                    <DetailsForm id="productInfo">
                        <Accordion
                            disableGutters
                            expanded={expanded === 1}
                            onChange={() => toggleExpanded(1)}
                        >
                            <AccordionSummary>
                                Product Details
                                {expanded === 1 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isUpdatingDetails}
                                        errors={detailsErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductDetailsForm
                                    type={product.type}
                                    preview={!editing || expanded !== 1}
                                />
                                {isUpdatingDetails && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </DetailsForm>
                    <RouteForm id="route">
                        <Accordion
                            disableGutters
                            expanded={expanded === 2}
                            onChange={() => toggleExpanded(2)}
                        >
                            <AccordionSummary>
                                Route
                                {expanded === 2 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isUpdatingRoute}
                                        errors={routeErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductRouteForm
                                    showFull
                                    preview={!editing || expanded !== 2}
                                />
                                {isUpdatingRoute && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </RouteForm>
                    <CapacityForm id="capacity">
                        <Accordion
                            disableGutters
                            expanded={expanded === 3}
                            onChange={() => toggleExpanded(3)}
                        >
                            <AccordionSummary>
                                Capacity
                                {expanded === 3 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={
                                            isUpdatingCapacities ||
                                            isCapacitiesFetching
                                        }
                                        errors={capacityErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                {!partialCapacities.length && (
                                    <ProductCapacityForm
                                        preview={!editing || expanded !== 3}
                                    />
                                )}
                                {(isUpdatingCapacities ||
                                    isCapacitiesFetching) && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </CapacityForm>
                    <ScheduleForm id="schedule">
                        <Accordion
                            disableGutters
                            expanded={expanded === 4}
                            onChange={() => toggleExpanded(4)}
                        >
                            <AccordionSummary>
                                Schedule
                                {expanded === 4 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={
                                            isUpdatingSchedules ||
                                            isSchedulesFetching
                                        }
                                        errors={scheduleErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                {!partialSchedules.length && (
                                    <ProductScheduleForm
                                        preview={!editing || expanded !== 4}
                                    />
                                )}
                                {(isUpdatingSchedules ||
                                    isSchedulesFetching) && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </ScheduleForm>
                    <FinancialInformationForm id="financial">
                        <Accordion
                            disableGutters
                            expanded={expanded === 5}
                            onChange={() => toggleExpanded(5)}
                        >
                            <AccordionSummary>
                                Pricing and Financials
                                {expanded === 5 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isUpdatingPricing}
                                        errors={financialErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductFinancingForm
                                    preview={!editing || expanded !== 5}
                                    capacities={product.capacity?.capacities}
                                    schedules={product.schedule?.items}
                                />
                                {isUpdatingPricing && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </FinancialInformationForm>
                    <OptionsForm id="options">
                        <Accordion
                            disableGutters
                            expanded={expanded === 6}
                            onChange={() => toggleExpanded(6)}
                        >
                            <AccordionSummary>
                                Product Options
                                {expanded === 6 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isUpdatingOptions}
                                        errors={optionsErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductOptionForm
                                    preview={!editing || expanded !== 6}
                                    capacities={product.capacity?.capacities}
                                    schedules={product.schedule?.items}
                                />
                                {isUpdatingOptions && <LoadingOverlay />}
                            </AccordionDetails>
                        </Accordion>
                    </OptionsForm>
                    <Accordion
                        disableGutters
                        expanded={expanded === 7}
                        onChange={() => toggleExpanded(7)}
                    >
                        <AccordionSummary>Activity Log</AccordionSummary>
                        <AccordionDetails>
                            <ActivityHistory
                                entity="product"
                                lifecycle={product.lifecycle}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(ProductEditForm);
